<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <packages />
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Packages from '@/components/static-page/packages';
import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  name: 'packages-page',
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Packages,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('general.homepage'),
          to: '/',
        },
        {
          text: this.$t('packages.title'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
    metaTitle() {
      return this.$t('packages.metaTitle');
    },
    metaDescription() {
      return this.$t('packages.metaDescription');
    },
  },
};
</script>
