<template>
  <div class="packages--wrapper">
    <div class="packages--main-title">
      <div class="title--content">
        <div class="title--text">
          {{ $t('packages.bannerTitle') }}
        </div>
        <div class="desc--text" v-html="$t('packages.desc')"></div>
      </div>
    </div>
    <div class="container">
      <!--      <div class="row">-->
      <!--        <div class="col-12">-->
      <!--          <div class="intro&#45;&#45;wrapper">-->
      <!--            <div class="introduction" v-html="$t('packages.contentIntro')"></div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="packages--container">
        <div class="package--card basic">
          <h2 class="package--title">{{ $t('packages.basic.title') }}</h2>
          <ul class="package--features">
            <li v-for="(feature, index) in basicFeatures" :key="`basic-${index}`">
              <img src="@/assets/img/icons/checkmark-3.svg" alt="Check" />
              {{ feature }}
            </li>
          </ul>
          <div class="package--price">
            <span class="price">500 Ribu</span>
            <span class="duration">1 Bulan</span>
          </div>
          <!--          <button class="btn btn-primary package&#45;&#45;button">{{ $t('packages.buyNow') }}</button>-->
        </div>

        <div class="package--card elite">
          <div class="special--offer">
            <div>{{ $t('packages.special') }}</div>
            <div class="big--text">{{ $t('packages.offer') }}</div>
          </div>
          <h2 class="package--title">{{ $t('packages.elite.title') }}</h2>
          <ul class="package--features">
            <li v-for="(feature, index) in eliteFeatures" :key="`elite-${index}`">
              <img src="@/assets/img/icons/checkmark-3.svg" alt="Check" />
              {{ feature }}
            </li>
          </ul>
          <div class="package--price">
            <span class="price">2,5 Juta</span>
            <span class="duration">6 Bulan</span>
          </div>
          <!--          <button class="btn btn-primary package&#45;&#45;button">{{ $t('packages.buyNow') }}</button>-->
        </div>

        <div class="package--card professional">
          <h2 class="package--title">{{ $t('packages.professional.title') }}</h2>
          <ul class="package--features">
            <li v-for="(feature, index) in professionalFeatures" :key="`professional-${index}`">
              <img src="@/assets/img/icons/checkmark-3.svg" alt="Check" />
              {{ feature }}
            </li>
          </ul>
          <div class="package--price">
            <span class="price">1,2 Juta</span>
            <span class="duration">3 Bulan</span>
          </div>
          <!--          <button class="btn btn-primary package&#45;&#45;button">{{ $t('packages.buyNow') }}</button>-->
        </div>
      </div>

      <!--      <div class="contact&#45;&#45;info">-->
      <!--        <p>{{ $t('packages.contactUs') }}:</p>-->
      <!--        <div class="contact&#45;&#45;methods">-->
      <!--          <a href="https://www.belirumah.co" target="_blank" class="contact&#45;&#45;method">-->
      <!--            &lt;!&ndash;            <img src="@/assets/img/icons/web.svg" alt="Website" />&ndash;&gt;-->
      <!--            www.belirumah.co-->
      <!--          </a>-->
      <!--          <a href="tel:0813-8547-5283" class="contact&#45;&#45;method">-->
      <!--            &lt;!&ndash;            <img src="@/assets/img/icons/phone.svg" alt="Phone" />&ndash;&gt;-->
      <!--            0813-8547-5283-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->

      <button class="btn btn-primary contact--btn" @click="handleContact">
        {{ $t('packages.contactUs') }}
      </button>
    </div>

    <div class="packages--footer" style="margin-bottom: 100px">
      <div class="container">
        <div class="packages--faq">
          <div class="faq--title">{{ $t('packages.faq.title') }}</div>
          <ol>
            <li>
              <div class="faq--question">{{ $t('packages.faq.q1') }}</div>
              <div class="faq--answer">{{ $t('packages.faq.a1') }}</div>
            </li>
            <li>
              <div class="faq--question">{{ $t('packages.faq.q2') }}</div>
              <div class="faq--answer">{{ $t('packages.faq.a2') }}</div>
            </li>
            <li>
              <div class="faq--question">{{ $t('packages.faq.q3') }}</div>
              <div class="faq--answer">{{ $t('packages.faq.a3') }}</div>
            </li>
            <li>
              <div class="faq--question">{{ $t('packages.faq.q4') }}</div>
              <div class="faq--answer">{{ $t('packages.faq.a4') }}</div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'packages',
  components: {},
  computed: {
    basicFeatures() {
      return [
        this.$t('packages.features.socialMediaAds'),
        this.$t('packages.features.newPropertyListing'),
        this.$t('packages.features.propertyManagement'),
        this.$t('packages.features.monthlyReport'),
      ];
    },
    eliteFeatures() {
      return [
        this.$t('packages.features.socialMediaAds'),
        this.$t('packages.features.leadsGenerate'),
        this.$t('packages.features.newPropertyListing'),
        this.$t('packages.features.propertyManagement'),
        this.$t('packages.features.marketingContent'),
        this.$t('packages.features.bannerTakeover'),
        this.$t('packages.features.monthlyReport'),
      ];
    },
    professionalFeatures() {
      return [
        this.$t('packages.features.socialMediaAds'),
        this.$t('packages.features.newPropertyListing'),
        this.$t('packages.features.propertyManagement'),
        this.$t('packages.features.monthlyReport'),
      ];
    },
  },
  methods: {
    handleContact() {
      const contactNumber = this.contactNumber
        ? this.contactNumber
        : '+' + this.$t('general.phone1');
      let textMessage =
        'Halo, saya ingin tahu lebih lanjut mengenai layanan Listing Management Service.';
      let url = `https://api.whatsapp.com/send?phone=${contactNumber}&text=${textMessage}`;
      window.open(url, '_blank');
    },
  },
};
</script>
